<template>
  <div v-if="is_super==true || is_admin_sb==true">
    <div class="row p-1">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.typeofjackpot') }}</div>
      <div class="col-2">{{ data.jackpots_grade }}</div>
      <div class="row hyperlink">
         <div class="col"><p v-if="showTmpl" @click="getTmepl">{{ $t('billmanager.billmanagerjackpotsedit.add_from_template') }}</p></div>
      </div>
    </div>
    <label v-if="data.is_global==false"><input type="checkbox" v-model="data.jackpot_status"> {{ $t('app.public.activity') }}</label><br>
    <label><input type="checkbox" @change="changeGlobal++" v-model="data.is_global"> {{ $t('billmanager.billmanagerjackpotsedit.isGlobal') }}</label>
    <div v-if="data.is_global==true" class="row p-1">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.parent') }}</div>
      <div class="col-3">
        <Select2 @select="parentChange($event)" v-model="data.parent" :options="parent_list" :settings="{ width: '100%', allowClear: true }" />
        <span v-if="validerr.get('parent')" class="validerr">{{validerr.get('parent')}}</span>
      </div>
    </div>
    <div class="row p-1">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.description') }}</div>
      <div class="col"><textarea v-model="data.description"></textarea>
        <span v-if="validerr.get('description')" class="validerr">{{validerr.get('description')}}</span>
      </div>
    </div>
    <div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.contributionpercentage') }}</div>
        <div class="col">
          <input type="number" step='0.01' max="100" :disabled="data.is_global==true" v-model="data.contribution_percentage">
          <span v-if="validerr.get('contribution_percentage')" class="validerr">{{validerr.get('contribution_percentage')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.lowerthreshold') }}</div>
        <div class="col">
          <input
              type="number"
              min="0"
              :disabled="data.is_global==true"
              @change="change_lower_threshold"
              @keyup="change_lower_threshold"
              v-model="data.lower_threshold">
          <span v-if="validerr.get('lower_threshold')" class="validerr">{{validerr.get('lower_threshold')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.upperthreshold') }}</div>
        <div class="col"><input min="0" type="number" :disabled="data.is_global==true" v-model="data.upper_threshold">
          <span v-if="validerr.get('upper_threshold')" class="validerr">{{validerr.get('upper_threshold')}}</span>
        </div>
      </div>
      <div class="row p-1" v-if="isaadj=='edit'">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.currentthreshold') }}</div>
        <div class="col"><span>{{ data.current_threshold }}</span></div>
      </div>
      <div class="row p-1" v-if="isaadj=='edit'">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.currentaccumulation') }}</div>
        <div class="col"><input type="number" min="0" :disabled="data.is_global==true" v-model="data.current_accumulation">
          <span v-if="validerr.get('current_accumulation')" class="validerr">{{validerr.get('current_accumulation')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.minbetamount') }}</div>
        <div class="col"><input type="number" min="0" :disabled="data.is_global==true" v-model="data.min_bet_amount">
          <span v-if="validerr.get('min_bet_amount')" class="validerr">{{validerr.get('min_bet_amount')}}</span>
        </div>
      </div>
    </div>

    <button @click="Apply()" class="btn-success m-1">{{ $t('app.public.apply') }}</button>
    <button @click="Cancel()" class="btn-danger m-1">{{ $t('app.public.cancel') }}</button>
  </div>

  <div v-if="is_super==false && is_admin_sb==false">
    <div class="row p-1">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.typeofjackpot') }}</div>
      <div class="col">{{ data.jackpots_grade }}</div>
    </div>
    <label v-if="data.is_global==false"><input type="checkbox" :disabled="true" v-model="data.jackpot_status"> {{ $t('app.public.activity') }}</label><br>
    <label><input type="checkbox" :disabled="true" v-model="data.is_global"> {{ $t('billmanager.billmanagerjackpotsedit.isGlobal') }}</label>
    <div v-if="data.is_global==true" class="row p-1">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.parent') }}</div>
      <div class="col-3">
        <Select2 @select="parentChange($event)" :disabled="true" v-model="data.parent" :options="parent_list" :settings="{ width: '100%', allowClear: true }" />
      </div>
    </div>
    <div class="row p-1">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.description') }}</div>
      <div class="col"><span>{{ data.description }}</span></div>
    </div>
    <div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.contributionpercentage') }}</div>
        <div class="col"><span>{{ data.contribution_percentage }}</span></div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.lowerthreshold') }}</div>
        <div class="col"><span>{{ data.lower_threshold }}</span></div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.upperthreshold') }}</div>
        <div class="col"><span>{{ data.upper_threshold }}</span></div>
      </div>
      <div class="row p-1" v-if="isaadj=='edit'">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.currentaccumulation') }}</div>
        <div class="col"><span>{{ data.current_accumulation }}</span></div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.minbetamount') }}</div>
        <div class="col"><span>{{ data.min_bet_amount }}</span></div>
      </div>
    </div>

    <button @click="Cancel()" class="btn-danger m-1">{{ $t('app.public.close') }}</button>
  </div>
</template>

<script setup>
import {ref, inject, computed, watch, onMounted} from "vue";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import BillService from "../../services/bill.service";
import {useStore} from "vuex";
import JackpotService from "../../services/jackpot.service";

const store = useStore()

const $t = inject('$t')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

const currentBillInfo = computed(() => store.state.bill.selOneBillInfo)

const changeGlobal = ref(0)

const oldShowHideTree = ref(false)
const showHideTree = inject('showHideTree')
const blockTree = inject('blockTree')
const unblockTree = inject('unblockTree')

const mask_percent = inject('mask_percent')
const maskpercent={ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9.,]/ }}}

const set_result = inject('set_result')

const SelOneBill = inject('SelOneBill')
const game_id = inject('game_id')
const grade_next = inject('grade_next')
const grade_edit = inject('grade_edit')
const jackpot_id = inject('jackpot_id')
const getDataBillGames = inject('getDataBillGames')
const isaadj = inject('isaadj')
const fromCP = inject('fromCP')

const JPTmpl = ref({})

const data = ref({
  'is_global': false,
  'parent': '',
  'jackpots_grade': grade_next.value,
  'description': '',
  'contribution_percentage': '',
  'lower_threshold': '',
  'upper_threshold': '',
  'current_threshold': '',
  'current_accumulation': '',
  'min_bet_amount': ''
})

const prev_data=ref({})

const prev_lower_threshold=ref('')

const parent = ref('')
const parent_list = ref([])

const addJackpots = inject('addJackpots')

const validatas = inject('validatas')
const validerr = ref(new Map())
const verdata = ref(new Map([
  ['description', {reg: '.+'}],
  ['contribution_percentage', {reg: '^[0-9]{1,2}([.][0-9]{0,2})?$|^100$', 'mess': $t(('app.validate.incorrect_value_percent'))}],
  ['lower_threshold', {reg: '[0-9]+'}],
  ['upper_threshold', {reg: '[0-9]+'}],
  ['min_bet_amount', {reg: '[0-9]+'}]
]));

if(isaadj.value=='edit') {
  verdata.value.set('current_accumulation', {reg: '[0-9]+'})
}

const saveJP = inject('saveJP')

function Apply()
{
  if (fromCP) {
      if(parseFloat(data.value.current_accumulation)>parseFloat(data.value.upper_threshold)) {
        if (confirm($t('billmanager.billmanagerjackpotsedit.upper_threshold_more_current_accumulation')) === false) {
          return
        }
      }

      if(data.value.is_global==true) {
        verdata.value.set('parent', {reg: '.+'})
      }

      validerr.value = validatas(data.value, verdata.value)

      if(validerr.value.size==0) {
        if (data.value.jackpot_status == true) {
          data.value.jackpot_status = 'active'
        } else {
          data.value.jackpot_status = 'not_active'
        }

        if (!jackpot_id) {
          data.value.grade_code = grade_next.value
        }

        saveJP(game_id.value, grade_next.value, data.value)
        unblockTree(oldShowHideTree.value)
        addJackpots('close')
      }

  } else {
    if(parseFloat(data.value.current_accumulation)>parseFloat(data.value.upper_threshold)) {
      if (confirm($t('billmanager.billmanagerjackpotsedit.upper_threshold_more_current_accumulation')) === false) {
        return
      }
    }

    if(data.value.is_global==true) {
      verdata.value.set('parent', {reg: '.+'})
    }

    validerr.value = validatas(data.value, verdata.value)

    if(validerr.value.size==0) {
      if(data.value.jackpot_status==true) {
        data.value.jackpot_status='active'
      } else {
        data.value.jackpot_status='not_active'
      }
      data.value.grade_code=grade_next.value

      JackpotService.save(SelOneBill.value, game_id.value, data.value, jackpot_id.value).then(
          () => {
            getDataBillGames()
            unblockTree(oldShowHideTree.value)
            addJackpots('close')
          },
          (error) => {
            let nam_err=''
            let err_data=error.response.data.err_info
            if(err_data.indexOf('###not_global###')!=-1) {
              let result = err_data.match(/[*][*][*](.*)[*][*][*]/);
              nam_err=$t('jackpot.jackpotedit.parent_list')+': '+result[1]
            }

            set_result(error.response.status, error, nam_err)
          }
      )
    }
  }
}

function Cancel()
{
  if(fromCP) {
    data.value.jackpot_status=prev_data.value.jackpot_status
    data.value.is_global=prev_data.value.is_global
    data.value.description=prev_data.value.description
    data.value.contribution_percentage=prev_data.value.contribution_percentage,
    data.value.lower_threshold=prev_data.value.lower_threshold,
    data.value.upper_threshold=prev_data.value.upper_threshold,
    data.value.current_accumulation=prev_data.value.current_accumulation,
    data.value.min_bet_amount=prev_data.value.min_bet_amount
  }

  unblockTree(oldShowHideTree.value)
  addJackpots('close')
}

const jackpots = inject('jackpots')
function getData(jackpot_id, bill_id)
{
  let old = data.value

  if(fromCP) {
    for(let key in jackpots.value) {
      let cur=jackpots.value[key]

      if(cur.id==game_id.value) {
        for(let keyj in cur.jackpots) {
          let curj = cur.jackpots[keyj]

          if(curj.grade_code==grade_edit.value) {
            let jp = curj

            prev_data.value = {...jp.data}

            data.value = jp.data
            data.value.jackpots_grade = jp.grade_code

            prev_lower_threshold.value = data.value.lower_threshold

            if (jp.data.is_global == 'true') {
              data.value.is_global = true
            }
            if (jp.data.is_global == 'false') {
              data.value.is_global = false
            }

            if (jp.data.jackpot_status == 'active') {
              data.value.jackpot_status = true
            } else {
              data.value.jackpot_status = false
            }
          }
        }
      }
    }
  } else {
    JackpotService.get(jackpot_id, bill_id).then(
      (response) => {
        if(response.data.is_global=='true') {
          response.data.is_global=true
        }
        if(response.data.is_global=='false') {
          response.data.is_global=false
        }

        if(response.data.jackpot_status=='active') {
          response.data.jackpot_status=true
        } else {
          response.data.jackpot_status=false
        }

        data.value=response.data
        prev_lower_threshold.value=data.value.lower_threshold

        if(data.value.jackpots_grade!=undefined) {
          getJackpotsForParents(data.value.jackpots_grade, SelOneBill.value, game_id.value)
        }

        if(old.is_global==true) {
          data.value.is_global=old.is_global
          data.value.parent=old.parent
          jackpot_id.value=''
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function getDataParent(jackpot_id, bill_id)
{
  JackpotService.get(jackpot_id, bill_id).then(
      (response) => {
        data.value.description=response.data.description
        data.value.contribution_percentage=response.data.contribution_percentage,
        data.value.lower_threshold=response.data.lower_threshold,
        data.value.upper_threshold=response.data.upper_threshold,
        data.value.current_accumulation=response.data.current_accumulation,
        data.value.min_bet_amount=response.data.min_bet_amount
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getJackpotsForParents(jackpots_grade, bill_id, game_id)
{
  JackpotService.getJackpotsForParents(jackpots_grade, bill_id, game_id).then(
    (response) => {
      parent_list.value=response.data
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function showTmpl_fn()
{
  let res=false

  if(JPTmpl.value!=undefined && Object.keys(JPTmpl.value).length>0) {
    res=true
  }

  return res
}
const showTmpl=computed(showTmpl_fn)

function parentChange({id, text})
{
  getDataParent(id)
}

function change_lower_threshold()
{
  if(data.value.lower_threshold) {
    const lowerThreshold = parseFloat(data.value.lower_threshold);
    const prevLowerThreshold = parseFloat(prev_lower_threshold.value);
    let currentAccumulation = null;
    if(data.value.current_accumulation) {
      currentAccumulation = parseFloat(data.value.current_accumulation);
    }

    let calcth = currentAccumulation - prevLowerThreshold + lowerThreshold;

    calcth = calcth.toFixed(2);

    if (calcth < lowerThreshold) {
      calcth = lowerThreshold;
    }

    data.value.current_accumulation = calcth;

    prev_lower_threshold.value = lowerThreshold;
  } else {
    data.value.current_accumulation = '';
  }
}

function isGlobalFn(nw)
{
  if(data.value.is_global==false) {
    data.value.parent=''
    data.value.contribution_percentage=0
    data.value.lower_threshold=0
    data.value.upper_threshold=0,
    data.value.current_threshold=0
    data.value.current_accumulation=0
    data.value.min_bet_amount=0

    JackpotService.getLocal(SelOneBill.value, game_id.value, data.value.jackpots_grade).then(
        (response) => {
          if (response.data.id!==undefined) {
            data.value.contribution_percentage=response.data.contribution_percentage,
            data.value.lower_threshold=response.data.lower_threshold,
            data.value.upper_threshold=response.data.upper_threshold,
            data.value.current_threshold=response.data.current_threshold,
            data.value.current_accumulation=response.data.current_accumulation,
            data.value.min_bet_amount=response.data.min_bet_amount
          }
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}
watch(changeGlobal, isGlobalFn)

function getTmepl()
{
  let cur = JPTmpl.value

  if (cur.jackpot_status=='active') {
    data.value.jackpot_status = true
  }
  if (!data.value.description.trim()) {
    data.value.description = cur.description;
  }
  data.value.contribution_percentage=cur.contribution_percentage
  data.value.lower_threshold=cur.lower_threshold
  data.value.upper_threshold=cur.upper_threshold
  data.value.min_bet_amount=cur.min_bet_amount

  change_lower_threshold()
}

function getJPTmpl()
{
  let grade = data.value.jackpots_grade
  let currency = currentBillInfo.value.currency

  JackpotService.getJPTmpl(grade, currency).then(
      (response) => {
        JPTmpl.value=response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function mounted_fn()
{
  oldShowHideTree.value=showHideTree.value
  blockTree()

  if(jackpot_id.value!='') {
    getData(jackpot_id.value, SelOneBill.value)
  } else {
    getJackpotsForParents(grade_next.value, SelOneBill.value, game_id.value)
  }

  getJPTmpl(data.value.jackpots_grade, currentBillInfo.value.currency)
}

onMounted(mounted_fn)
</script>

<style>
.hyperlink {
  color: #007BFF;
  text-decoration: underline;
  cursor: pointer;
}
.hyperlink:hover {
  color: #0056b3;
}
</style>
